<template>
  <div>
    <!--縮圖-->
    <v-img
      class="white--text align-end lighten-2 cursor-pointer"
      @click="view"
      :class="imageClass"
      :src="photoUrl"
      :lazy-src="tinyPhotoUrl"
      :width="width"
      :height="height"
    >
      <template v-slot:placeholder>
        <v-row
          v-if="!photoUrl"
          eagle-list="photo-placeholder"
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-icon size="22">{{placeholderIcon}}</v-icon>
        </v-row>
      </template>
    </v-img>

    <list-table-text
      v-if="textConfig"
      :listKey="listKey"
      :row="row"
      :name="name"
      :index="index"
      :config="textConfig"
    ></list-table-text>

    <!--圖片檢視器-->
    <photo-viewer
      v-if="photoUrl"
      ref="photoViewer"
      :photo="photo"
    ></photo-viewer>
  </div>
</template>

<script lang="babel" type="text/babel">
import listDataItem from '@/components/list/mixins/listDataItem'
export default {
  mixins: [listDataItem],
  methods: {
    view() {
      if(!this.photoUrl) return
      if(!this.$refs.photoViewer) return null
      this.$refs.photoViewer.active()
    },
  },
  computed: {
    placeholderIcon() {
      if(this.config.placeholderIcon) {
        return this.config.placeholderIcon
      }

      return 'fa fa-image'
    },
    width() {
      return this.config.photoWidth || 80
    },
    height() {
      return this.config.photoHeight || 80
    },
    imageClass() {
      if(!this.photoUrl) return 'grey'
      return null
    },
    photo() {
      if(typeof this.config.photo === 'function') {
        return this.config.photo(this.row)
      }
      return this.itemData
    },
    photoUrl() {
      return this.$helper.getPhotoUrl(this.photo, ['small', 'middle'])
    },
    tinyPhotoUrl() {
      return this.$helper.getPhotoUrl(this.photo, ['tiny', 'small']) || ''
    },
    textConfig() {
      if(!this.config.textConfig) return null
      return this.config.textConfig
    },
  },
  components: {
    'list-table-text': () => import('@/components/list/tableMode/listTableTypes/text.vue'),
  }
}
</script>

<style lang="sass" type="text/sass" scoped></style>
